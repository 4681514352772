import { useEffect, useState } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer?: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google_optimize?: any;
  }
}

export default function useOptimizeExperiment(experimentId?: string, mtvIndexSectionPosition?: number) {
  const [variant, setVariant] = useState<string | undefined>();

  useEffect(() => {
    if (experimentId) {
      let intervalId: number | undefined;
      (async () => {
        intervalId = window.setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            let value: string | undefined = window.google_optimize.get(experimentId);

            if (typeof mtvIndexSectionPosition !== 'undefined' && value) {
              const sections = value.split('-');
              value = sections[mtvIndexSectionPosition];
            }

            setVariant(value);
            clearInterval(intervalId);
            intervalId = undefined;
          }
        }, 100);
      })();

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = undefined;
        }
      };
    }
  }, [experimentId, mtvIndexSectionPosition]);

  return variant;
}
