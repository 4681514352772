import React from 'react';
import SharedFocus from 'components/SharedFocus/SharedFocus';
import { PageProps } from 'gatsby';
import useOptimizeExperiment from '../../hooks/useOptimizeExperiment';
import useQueryParamsAsSingleValues from '../../hooks/useQueryParamsAsSingleValues';
import speakerHeroBackground from 'images/focus/speaker/speaker-background.png';
import stepOneImage from 'images/focus/steps/step-one.webp';
import stepTwoImage from 'images/focus/steps/step-two.webp';
import stepThreeImage from 'images/focus/steps/step-three.webp';
import { LANDING_PAGE_FOCUS } from '../../utils/constants';

const DEFAULT_EXPERIMENT_ID = process.env.GATSBY_OPTIMIZE_LANDING_EXP_ID || 'v0ZWEBTiRe-FmdPnSBBZxQ';
const FORM_INDEX_SECTION_POSITION = process.env.GATSBY_OPTIMIZE_LANDING_FORM_INDEX || '1';

// Use this to change the copy text on the Speaker landing page
const speakerCopy = {
  intro: '',
  header: '',
  body: '',
};

const howItWorksStepsOverrides = [
  {
    image: stepOneImage,
    header: 'Easily Reserve Stagetime',
    body: ['Book online to reserve our professional stage', 'Our producers will reach out to finalize the rest'],
  },
  {
    image: stepTwoImage,
    header: 'Sell Your Livestream',
    body: [
      'Create instantly purchasable NFTs with the push of a button',
      'Stream your presentation to thousands of fans & viewers',
    ],
  },
  {
    image: stepThreeImage,
    header: 'Acquire New Audiences',
    body: ['Use your collectibles to get in front of new fans', 'We help you promote your collectibles after the show'],
  },
];

const SpeakerPage = ({ location }: PageProps): JSX.Element => {
  const {
    setOverride,
    experimentId = DEFAULT_EXPERIMENT_ID,
    indexPosition = FORM_INDEX_SECTION_POSITION,
  } = useQueryParamsAsSingleValues(location.search);
  const overrideSetValue = setOverride != null ? setOverride : undefined;

  const variant = useOptimizeExperiment(experimentId, parseInt(indexPosition, 10));

  return (
    <SharedFocus
      focusSetOverride={overrideSetValue != null ? overrideSetValue : variant}
      experimentId={experimentId}
      formIndexSectionPosition={parseInt(indexPosition, 10)}
      heroBackground={speakerHeroBackground}
      heroContentCopy={speakerCopy}
      howItWorksStepsOverrides={howItWorksStepsOverrides}
      focusType={LANDING_PAGE_FOCUS.Speaker}
      canonicalUrl='/landing-page/speaker/'
    />
  );
};

export default SpeakerPage;
